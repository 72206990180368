import { useRouter } from 'next/router';
import { LOGO_192, WEB_TITLE } from 'src/constants/layout';
import { APP_IMG, SHOW_COMMEMORATE } from 'src/constants';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/services/member-auth/datalayer/send-datalayer';
const HeaderLogo = () => {
  const router = useRouter();
  return (
    <>
      <div className='header-logo' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        <a
          href='/'
          img-fluid='true'
          title='logo'
           //! DATALAYER
           onClick={() =>
             sendDataLayerGlobal({
               type: DATALAYER_TYPE.TRACK_POSITION,
               router: router?.pathname,
               position: 'on:header',
               section: 'header',
               data: {
                 title: 'logo',
                 heading: 'logo'
               }
             })
          }
        >
          {
            router.asPath === '/' &&
            <h1>
              <span> {WEB_TITLE} </span>
            </h1>
          }

          <img src={`${APP_IMG}/static/${LOGO_192}`} alt='logo-header' title={WEB_TITLE} width='48' height='48' />
        </a>
      </div>
    </>
  );
};

export default HeaderLogo;
